<template>
  <v-app-bar
    v-scroll="onScroll"
    app
    :style="fillHeader"
    :elevation="elevation"
  >
    <v-container>
      <v-row justify="center">
        <v-col cols="4" md="3" xl="2">
          <v-toolbar-title>
            <NuxtLink :to="{ name: 'index' }" class="color-inherit">
              <img src="../static/flick3.png" alt="Flick Club Logo" height="32" class="mt-2 d-none d-md-inline-block">
              <img src="../static/flick-mini.png" alt="Flick Club Logo" height="32" class="mt-2 d-md-none">
            </NuxtLink>
          </v-toolbar-title>
        </v-col>
        <v-col md="6" xl="5" class="d-none d-md-inline-block">
          <Search class="d-none d-md-flex align-content-center mt-1" />
        </v-col>
        <v-col cols="8" md="3" xl="2" class="d-flex align-end flex-column">
          <span v-if="$store.state.user === null">
            <SearchToolbar />

            <Navigation />
            <NavigationToolbar />

            <SignIn />
            <SignUp />
          </span>
          <span v-else>
            <SearchToolbar />

            <Navigation />
            <NavigationToolbar />

            <Notification />
            <NotificationToolbar />

            <Menu />
            <MenuToolbar />
          </span>
        </v-col>
      </v-row>
      <Rate />
      <Status />
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  components: {
    SignIn: () => import("@/components/bar/SignIn"),
    SignUp: () => import("@/components/bar/SignUp"),
    //
    Menu: () => import("@/components/bar/desktop/MenuDesktop.vue"),
    MenuToolbar: () => import("@/components/bar/mobile/MenuMobile.vue"),
    //
    Search: () => import("@/components/bar/desktop/SearchDesktop.vue"),
    SearchToolbar: () => import("@/components/bar/mobile/SearchMobile.vue"),
    //
    Navigation: () => import("@/components/bar/desktop/NavigationDesktop.vue"),
    NavigationToolbar: () => import("@/components/bar/mobile/NavigationMobile.vue"),
    //
    Notification: () => import("@/components/bar/desktop/NotificationDesktop.vue"),
    NotificationToolbar: () => import("@/components/bar/mobile/NotificationMobile.vue"),
    //
    Rate: () => import("@/components/blocks/Rate"),
    Status: () => import("@/components/blocks/Status"),
  },
  data: () => ({
    dialog: false,
    fillHeader: {
      backgroundColor: "unset",
      zIndex: 1000,
    },
    elevation: 0,
  }),
  mounted () {
    if (typeof window === "undefined") { return; }
    const top = window.pageYOffset || document.documentElement.scrollTop;
    if (top > 20) {
      this.fillHeader.backgroundColor = "#121212";
      this.elevation = 2;
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === "undefined") { return; }
      const top = window.pageYOffset || e.target.scrollTop || 0;
      if (top > 20) {
        this.fillHeader.backgroundColor = "#121212";
        this.elevation = 2;
      } else {
        this.fillHeader.backgroundColor = "unset";
        this.elevation = 0;
      }
    },
  },
};
</script>
